import React from 'react'

export default function ShareButton() {
  return (
    <div className="shareButton">
      <a href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fcovid-simulacija.si%2F" target="_blank" rel="noopener noreferrer">
        <button className="fbShare">
          <img src={require('../img/fb.svg')} alt="" /> Deli na fb
        </button>
      </a>
    </div>
  )
}
