/**
 * This class is responsible for setting and updating properties for each particle in the simulation
 *
 */

import { getDistance, resolveCollision, randomFloatFromRange, randomIntFromRange } from './utils'
import colors from './colors'
export default class Particle {
  constructor(x, y, radius, speedSetting, speedMultiplier, isolated, state, framesToRecover, isolatedInfectionLikelihood) {
    this.x = x
    this.y = y
    this.r = radius
    this.size = radius * 2
    this.isolated = isolated
    this.static = isolated ? true : false
    this.mass = isolated ? 1000 : 1
    this.color = colors[state]
    this.framesToRecover = framesToRecover
    this.state = state
    this.speedSetting = speedSetting
    this.speedMultiplier = speedMultiplier
    this.isolatedInfectionLikelihood = isolatedInfectionLikelihood

    if (isolated) {
      this.isolatedStroke = {
        width: 1, // Isolated particles stroke width
        color: '#596157' // Isolated particles stroke color
      }
    }

    this.velocity = {
      x: isolated ? 0 : this.getRandomVelocity(),
      y: isolated ? 0 : this.getRandomVelocity()
    }
  }

  getRandomVelocity() {
    const speedRange = [randomFloatFromRange(-0.4, -0.2), randomFloatFromRange(0.2, 0.4)]
    return speedRange[randomIntFromRange(0, 1)]
  }

  updateSpeed(newSpeedSetting) {
    this.speedSetting = newSpeedSetting
  }

  updateFramesToRecover(newFramesToRecover) {
    this.framesToRecover = newFramesToRecover
  }

  update(particles) {
    if (this.recoveredAtFrame && window.currentFrame >= this.recoveredAtFrame && this.state === 'infected') this.recovered()
    this.draw()

    this.x += this.velocity.x * this.speedMultiplier * this.speedSetting
    this.y += this.velocity.y * this.speedMultiplier * this.speedSetting

    this.checkBounds()

    // Check if this particle is colliding with other particles
    for (let s = 0; s < particles.length; s++) {
      const cp = particles[s]
      if (cp === this) continue
      if (getDistance(this.x, this.y, cp.x, cp.y) - this.size < 0) {
        /**
         * We have collision
         * Cases:
         * 1. Both particles are moving:
         *    - None of them are infected -> just bounce
         *    - One of them is infected -> transmit the virus
         *    - Both of them are infected  -> just bounce
         *
         * 2. One of the particles is isolated:
         *
         *    - Isolated particle is not infected
         *      - Colliding particle is infected --> Determine if isolated particle gets infected (likelihood)
         *      - Colliding particle is not infected -> just bounce
         *    - Isolated particle is infected
         *      - Colliding particle is infected -> just bounce
         *      - Colliding particle is not infected -> just bounce (isolated infected particles can not spread the virus)
         */

        if (!this.isolated && !cp.isolated) {
          if (cp.state === 'infected' && this.state !== 'infected') this.infected()
          else if (this.state === 'infected' && cp.state !== 'infected') cp.infected()
        } else if (this.isolated && this.state === 'healthy') {
          if (cp.state === 'infected') {
            // Determine if infected colliding particle transmits the virus on isolated person
            const transmission = Math.random() < this.isolatedInfectionLikelihood / 100
            if (transmission) this.infected()
          }
        }

        resolveCollision(this, cp)
      }
    }
  }

  infected() {
    // Can not be infected if it is recovered or it is dead
    if (this.state === 'recovered' || this.state === 'dead') return
    this.state = 'infected'
    this.recoveredAtFrame = window.currentFrame + this.framesToRecover
  }

  died() {
    this.state = 'dead'
    this.velocity.x = 0
    this.velocity.y = 0
    this.static = true
    this.mass = 1000
  }

  recovered() {
    this.state = 'recovered'
  }

  checkBounds() {
    if (this.x <= this.r || this.x >= window.canvas.width - this.r) this.velocity.x *= -1
    if (this.y <= this.r || this.y >= window.canvas.height - this.r) this.velocity.y *= -1
  }

  draw() {
    window.ctx.beginPath()
    window.ctx.fillStyle = colors[this.state]
    if (this.isolated) {
      window.ctx.lineWidth = this.isolatedStroke.width
      window.ctx.strokeStyle = this.isolatedStroke.color
      window.ctx.arc(this.x, this.y, this.r, 0, Math.PI * 2, true)
      window.ctx.fill()
      window.ctx.stroke()
    } else {
      window.ctx.arc(this.x, this.y, this.r, 0, Math.PI * 2, true)
      window.ctx.fill()
    }
    window.ctx.closePath()
  }
}
