import React, { useEffect } from 'react'
import Simulator from './components/Simulator'
import colors from './simulation/colors'
import TextContent from './components/TextContent'

function App() {
  useEffect(() => {
    // Set colors as CSS colors
    for (const state in colors) {
      if (colors.hasOwnProperty(state)) {
        const color = colors[state]
        document.documentElement.style.setProperty(`--${state}-color`, color)
      }
    }
  }, [])
  return (
    <div className="App">
      <div className="container">
        <header className="header">
          <h1>Vpliv samoizolacije na širjenje COVID-19</h1>
          <h2>Kako sploščiti krivuljo?</h2>
        </header>
        <TextContent></TextContent>
      </div>
      <div className="container noPadMobile">
        <Simulator></Simulator>
      </div>

      <div className="credits">
        <div className="container">
          <p style={{ maxWidth: 600 }}>
            Veseli bomo, če boste vizualizacijo uporabili na svojih spletnih straneh, portalih in drugod, kjer menite, da bi dosegla višjo stopnjo
            zavedanja socialne distance.<br></br> Za dodatne informacije pišite na leon.skrilec@gmail.com.
          </p>
          <ul>
            <li>Izdelano v samoizolaciji.</li>
            <li>
              <p></p>
            </li>
            <li>
              Avtor: Leon Škrilec
              <span className="icons">
                <a href="mailto:leon.skrilec@gmail.com">
                  <img src={require('./img/envelope.svg')} alt="Leon Škrilec Email" />
                </a>
                <a href="https://github.com/LeonSkrilec" target="_blank" rel="noopener noreferrer">
                  <img src={require('./img/github.svg')} alt="Leon Škrilec Github" />
                </a>
                <a href="https://www.linkedin.com/in/leon-škrilec-67317792" target="_blank" rel="noopener noreferrer">
                  <img src={require('./img/linked.svg')} alt="Leon Škrilec LinkedIn" />
                </a>
              </span>
            </li>
            <li>Support: Romana Sansoni</li>
            <li>
              Ideja:{' '}
              <a href="https://www.washingtonpost.com/graphics/2020/world/corona-simulator/" target="_blank" rel="noopener noreferrer">
                Washington post
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default App
