import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import App from './App.js'
import Embed from './Embed.js'
import EmbedTest from './EmbedTest.js'
// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function BasicExample() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <App />
          </Route>
          <Route path="/embed">
            <Embed />
          </Route>
          <Route path="/embed-test">
            <EmbedTest />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}
