import React from "react";
import { useHardwareConcurrency } from "react-adaptive-hooks/hardware-concurrency";
import { useMemoryStatus } from "react-adaptive-hooks/memory";
import { useNetworkStatus } from "react-adaptive-hooks/network";

const withPerfData = Component => props => {
  const { numberOfLogicalProcessors } = useHardwareConcurrency();
  const { deviceMemory } = useMemoryStatus();
  const { effectiveConnectionType } = useNetworkStatus();
  const perfProps = {
    numberOfLogicalProcessors,
    deviceMemory,
    effectiveConnectionType
  };
  return <Component {...props} {...perfProps}></Component>;
};

export default withPerfData;
