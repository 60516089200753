import React from 'react'
import PropTypes from 'prop-types'

function Results(props) {
  return (
    <div className="results">
      {props.results.map((r, index) => {
        return (
          <div className="result" key={index}>
            <div className="result-summary">
              Delež izoliranih: {r.isolated} % <br></br>
            </div>
            <div className="result-image">
              <img src={r.image} alt="Prikaz simulacije" />
            </div>
          </div>
        )
      })}
    </div>
  )
}

Results.propTypes = {
  results: PropTypes.array.isRequired
}

export default Results
