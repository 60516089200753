import colors from './colors'

export default class ProgresChart {
  constructor(canvas, totalParticles, speed) {
    this.canvas = document.getElementById(canvas.id)
    this.ctx = this.canvas.getContext('2d')
    this.ctx.fillStyle = colors['healthy']
    this.ctx.fillRect(0, 0, canvas.width, canvas.height)
    this.width = canvas.width
    this.height = canvas.height
    this.totalParticles = totalParticles

    this.chartX = 0 // We start at x = 0;
    // Line width equals to speed
    this.lineWidth = 2 * speed
    this.ctx.lineWidth = this.lineWidth
    this.lineHeights = {
      infected: 0,
      recovered: 0
    }
  }

  update(stateCounts) {
    /**
     * stateCounts = {
        healthy: 200,
        infected: 1,
        recovered: 0,
        dead: 0
     * }
    */
    // Whole chart/canvas represents healthy people.
    // We only fill the chart with infected and recovered lines
    // Infected lines start from bottom
    // Recovered start from top
    // We will not plot dead ones, bc it is hard to plot 4 variables on single chart

    const infectedLineHeight = this.calculateLineHeight(stateCounts.infected)
    const recoveredLineHeight = this.calculateLineHeight(stateCounts.recovered)

    this.drawInfectedLine(infectedLineHeight)
    this.drawRecoveredLine(recoveredLineHeight)

    this.lineHeights.infected = infectedLineHeight
    this.lineHeights.recovered = recoveredLineHeight

    // We move drawing area to the right by line width
    this.chartX += this.lineWidth
  }

  calculateLineHeight(particleCount) {
    const maxHeight = this.height
    const percentHeight = particleCount / this.totalParticles
    return maxHeight * percentHeight
  }

  drawInfectedLine(lineHeight) {
    this.ctx.beginPath()
    this.ctx.strokeStyle = colors['infected']
    this.ctx.moveTo(this.chartX, this.height)
    this.ctx.lineTo(this.chartX, this.height - lineHeight)
    this.ctx.stroke()
  }
  drawRecoveredLine(lineHeight) {
    this.ctx.beginPath()
    this.ctx.strokeStyle = colors['recovered']
    this.ctx.moveTo(this.chartX, 0)
    this.ctx.lineTo(this.chartX, lineHeight)
    this.ctx.stroke()
  }

  draw() {}
}
