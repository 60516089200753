import React from 'react'

function EmbedTest(props) {
  return (
    <div style={{ padding: '1000px 0 1000px' }}>
      <iframe src="http://192.168.0.12:3000/embed" width="800" height="600" title="Embed test"></iframe>
    </div>
  )
}

export default EmbedTest
