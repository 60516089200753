/**
 * This class is responsible for handling drawing on canvas
 * It is supposed to initialize simulation and listen for parameter changes
 * Parameters:
 * - Particle count (either 100, 200, 500, 1000)
 * - Isolated (percentage of static particles)
 * - Speed (speed of particle movement)
 */

import Particle from './Particle'
import Counters from './Counters'
import ProgressChart from './ProgressChart'
import { getDistance, randomIntFromRange } from './utils'

class Simulation {
  /**
   * In the constructor we need to initialize the canvas and check if it exists
   */
  constructor({ canvas, count, isolated, speed, speedMultiplier, progressCanvas }) {
    this.canvas = document.getElementById(canvas.id)
    this.ctx = this.canvas.getContext('2d', { alpha: false })
    window.ctx = this.ctx
    window.canvas = this.canvas
    this.width = canvas.width
    this.height = canvas.height

    this.speedMultiplier = speedMultiplier
    this.speedSetting = speed
    this.isolatedPercentage = isolated
    this.isolatedInfectionLikelihood = 100 - this.isolatedPercentage // How likely is that isolated particle gets infected.

    this.duration = 20 // Desired duration in seconds
    this.totalFrames = this.duration * 60
    this.currentFrame = window.currentFrame = 0

    this.particleCount = count
    this.particleSize = 10
    this.particleRadius = this.particleSize / 2

    this.particleFramesToRecover = this.totalFrames / 4

    // Object to hold counts of particles in each state
    // Conusmed by ProgressChart
    this.stateCounts = {}

    this.progressCanvas = progressCanvas

    this.state = {
      initialized: false,
      playing: false,
      started: false,
      firstFrame: false
    }

    this.init()
  }

  init() {
    this.currentFrame = window.currentFrame = 0
    this.particles = []
    this.createInfectedParticle()
    this.createParticles()
    this.counters = new Counters(this.particles)
    this.progressChart = new ProgressChart(this.progressCanvas, this.particleCount, this.speedSetting)
    const numberOfChartLines = this.progressChart.width / this.progressChart.lineWidth
    this.lineEveryNthFrame = Math.floor(this.totalFrames / numberOfChartLines)

    console.log(this.lineEveryNthFrame)

    this.raf = null

    if (!this.state.playing) {
      // Draw initial frame
      setTimeout(() => {
        this.state.firstFrame = true
        this.animate()
      }, 10)
    }

    this.state.initialized = true
  }

  stop() {
    if (this.raf) {
      cancelAnimationFrame(this.raf)
      this.raf = null
    }
    this.state.playing = false
    this.state.initialized = false
  }

  restart() {
    this.stop()
    this.init()
    this.play()
  }

  end() {
    this.stop()
    if (this.onEnd) this.onEnd()
  }

  play() {
    const gtag = window.gtag
    if (gtag) {
      gtag('event', 'play', { event_label: 'simulation_played' })
    }
    this.state.playing = true
    this.state.started = true

    // If play is called without initializing simualtion
    // This can happen if simulation was played once and user did not change any settings
    if (!this.state.initialized) this.init()
    if (this.onStart) this.onStart()

    // Because we have drawn our particles already we have to manually
    // set timeout on the infected particle when we first start the simulation
    // On subsequent restarts, particles are drawn when animation starts so it is no problem
    this.setFirstInfectedTimeout()

    this.animate()
  }

  createParticles() {
    const getX = () => randomIntFromRange(this.particleSize, this.width - this.particleSize)
    const getY = () => randomIntFromRange(this.particleSize, this.height - this.particleSize)

    for (let i = 0; i < this.particleCount - 1; i++) {
      let x = getX()
      let y = getY()

      // All staring particles are healthy
      const state = 'healthy'
      // Randomly isolated people.
      const isolated = Math.random() < this.isolatedPercentage / 100

      // Make sure there is no overalpping at start
      // Only check when there are at least two particles
      // Isolated particles are allowed to overlap!
      if (i !== 0 && !isolated) {
        for (let j = 0; j < this.particles.length; j++) {
          const p = this.particles[j]
          if (getDistance(x, y, p.x, p.y) - this.particleSize < 0) {
            x = getX()
            y = getY()
            j = -1
          }
        }
      }

      const particle = new Particle(
        x,
        y,
        this.particleRadius,
        this.speedSetting,
        this.speedMultiplier,
        isolated,
        state,
        this.particleFramesToRecover,
        this.isolatedInfectionLikelihood
      )
      this.particles.push(particle)
    }
  }

  createInfectedParticle() {
    // Place infected somewhere in the middle and speed it up a bit
    const cw = this.width
    const ch = this.height
    let x = randomIntFromRange(cw / 3, cw - cw / 3)
    let y = randomIntFromRange(ch / 3, ch - ch / 3)

    const state = 'infected'
    // Starting infected particle can not be isolated;
    const isolated = false
    this.firstInfectedParticle = new Particle(
      x,
      y,
      this.particleRadius,
      this.speedSetting,
      this.speedMultiplier * 1.4,
      isolated,
      state,
      this.particleFramesToRecover,
      this.isolatedInfectionLikelihood
    )
    this.particles.push(this.firstInfectedParticle)
  }

  setFirstInfectedTimeout() {
    this.firstInfectedParticle.infected()
  }

  updateCount(newCount) {
    this.particleCount = newCount
    if (!this.state.playing) this.init()
  }

  updateIsolatedPercentage(newPercentage) {
    this.isolatedPercentage = newPercentage
    this.isolatedInfectionLikelihood = 100 - this.isolatedPercentage
    if (!this.state.playing) this.init()
  }

  updateSpeed(newSpeed) {
    this.speedSetting = newSpeed
    this.totalFrames = (this.duration / newSpeed) * 60
    this.particleFramesToRecover = this.totalFrames / 4

    for (let i = 0; i < this.particles.length; i++) {
      this.particles[i].updateSpeed(newSpeed)
      this.particles[i].updateFramesToRecover(newSpeed)
    }

    if (!this.state.playing) this.init()
  }

  animate() {
    if (this.currentFrame >= this.totalFrames - this.lineEveryNthFrame) this.end()

    this.ctx.fillStyle = '#ffffff'
    this.ctx.fillRect(0, 0, this.width, this.height)

    for (let i = 0; i < this.particles.length; i++) {
      const particle = this.particles[i]
      if (this.state.playing) particle.update(this.particles, this.state.playing)
      else particle.draw()
    }

    if (this.currentFrame % this.lineEveryNthFrame === 0) {
      this.stateCounts = this.counters.update(this.particles)
      this.updateProgressChart()
    }

    this.currentFrame++
    window.currentFrame++

    if (this.state.playing) {
      this.raf = requestAnimationFrame(this.animate.bind(this))
    }
  }

  updateProgressChart() {
    this.progressChart.update(this.stateCounts)
  }
}

export default Simulation
