import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Controls extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    controls: PropTypes.array.isRequired,
    renderOnly: PropTypes.array,
    variant: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  optionClicked(control, value) {
    if (this.props.controls.find(c => c.id === control.id).value === value) return
    if (this.props.onChange) this.props.onChange(control.id, value)
  }

  render() {
    let controlsToRender = []
    if (this.props.renderOnly) {
      this.props.controls.forEach(control => {
        if (this.props.renderOnly.find(c => c === control.id)) {
          controlsToRender.push(control)
        }
      })
    } else {
      controlsToRender = this.props.controls
    }

    return (
      <div className={`controls ${this.props.variant ? this.props.variant : ''}`}>
        {controlsToRender.map(control => {
          return (
            <div className="control" key={control.id}>
              <div className="label">{control.label}</div>
              <div className="options">
                {control.options.map((option, index) => (
                  <button
                    key={option + '-' + index}
                    className={[control.value === option.value ? 'active' : '']}
                    onClick={() => this.optionClicked(control, option.value)}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default Controls
