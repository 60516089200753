import React, { Component } from 'react'
import Simulator from './components/Simulator'

export default class Embed extends Component {
  constructor(props) {
    super(props)

    const url = new URL(window.location.href)
    this.countParam = url.searchParams.get('count')
    this.isolatedParam = url.searchParams.get('isolated')

    if (this.countParam) this.countParam = parseInt(this.countParam)
    if (this.isolatedParam) this.isolatedParam = parseInt(this.isolatedParam)
  }

  componentDidMount() {
    const parentUrl = this.getParentUrl()
    if (parentUrl) {
      const gtag = window.gtag
      if (gtag) {
        gtag('set', {
          referrer: parentUrl
        })

        gtag('event', 'embedded', { event_label: parentUrl })
      }
    }
  }

  getParentUrl() {
    if (window.location !== window.parent.location) {
      return document.referrer
    } else return false
  }

  render() {
    return (
      <div className="embedWrap">
        <Simulator embedded={true} count={this.countParam} isolated={this.isolatedParam}></Simulator>
      </div>
    )
  }
}
