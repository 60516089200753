import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

export default class EmbedModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired,
    controls: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    Modal.setAppElement('#root')

    this.styles = {
      content: {
        position: 'relative',
        top: 'auto',
        right: 'auto',
        bottom: 'auto',
        left: 'auto'
      }
    }

    this.selectedOptions = {}
    this.options = { isolated: [] }
    this.props.controls.forEach(c => {
      if (c.id === 'isolated') {
        c.options.forEach(o => {
          this.options[c.id].push(o)
          if (o.value === c.value) {
            this.selectedOptions[c.id] = o
          }
        })
      }
    })

    this.state = {
      modalOpened: props.opened,
      useCurrentSettings: true,
      isolatedValue: 0,
      embedUrl: `https://covid-simulacija.si/embed?isolated=${this.selectedOptions['isolated'].value}`,
      iframeCode: ''
    }
  }

  componentDidMount() {
    this.updateEmbedUrl(this.selectedOptions['isolated'].value)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opened !== this.props.opened) {
      this.setState({ modalOpened: this.props.opened })
    }
  }

  currentSettingsChange() {
    this.setState({ useCurrentSettings: !this.state.useCurrentSettings }, () => {
      if (this.state.useCurrentSettings) {
        this.updateEmbedUrl(this.selectedOptions['isolated'].value)
      } else {
        this.updateEmbedUrl(this.state.isolatedValue)
      }
    })
  }

  closeModal() {
    this.setState({ modalOpened: false })
    if (this.props.onClose) this.props.onClose()
  }

  onSelectChange(property, value) {
    this.setState({ [property]: value })
    this.updateEmbedUrl(value)
  }

  updateEmbedUrl(isolated) {
    this.setState(
      {
        embedUrl: `https://covid-simulacija.si/embed?isolated=${isolated}`
      },
      () => {
        const iframeCode = `<iframe frameborder="0" height="600" width="100%" scrolling="no" title="Covid-19 simulacija" src="${this.state.embedUrl}"></iframe>`
        console.log(iframeCode)
        this.setState({ iframeCode })
      }
    )
  }

  render() {
    return (
      <Modal isOpen={this.state.modalOpened} onRequestClose={this.closeModal.bind(this)} style={this.styles} contentLabel="Example Modal">
        <div className="closeButton">
          <button onClick={this.closeModal.bind(this)}>
            <img src={require('../img/close.svg')} alt="Close modal" />
          </button>
        </div>

        <h3>Vdelaj simulacijo na svojo spletno stran</h3>
        <div className="form">
          <div className="controls">
            <div className="selectSettings">
              <div className="form-group">
                <label htmlFor="isolated">Delež izoliranih: </label>
                <select
                  name="isolatedSetting"
                  id="isolated"
                  value={this.state.isolatedValue}
                  onChange={e => this.onSelectChange('isolatedValue', e.target.value)}
                >
                  {this.options.isolated.map((o, i) => (
                    <option value={o.value} key={i}>
                      {o.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="link">
          <label htmlFor="embedLink">Povezava</label>
          <input readOnly type="text" id="embedLink" value={this.state.embedUrl} />
        </div>

        <div className="iframeCode">
          <label htmlFor="iframeCode">Primer vdelave</label>
          <textarea readOnly type="text" id="iframeCode" value={this.state.iframeCode}></textarea>
        </div>
      </Modal>
    )
  }
}
