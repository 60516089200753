import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import Simulation from '../simulation/Simulation'
import SimulationSummary from '../simulation/Summary'
import Controls from '../simulation/Controls'
import Results from './Results'
import EmbedModal from './EmbedModal'
import withPerfData from './withPerfData'

class Simulator extends Component {
  static propTypes = {
    embedded: PropTypes.bool,
    isolated: PropTypes.number,
    count: PropTypes.number,
    numberOfLogicalProcessors: PropTypes.number,
    deviceMemory: PropTypes.number
  }
  /**
   *
   * How is this gonna work?
   * Okaaay... We need to have control over animation on the canvas.
   * So we need to control when to start animation, when to update particles count, speed of animation etc.
   * This component receives all the changes from controls. How to pass them to the animation?
   * We can create a separate class for animation and particles etc. and expose some methods on that class.
   * Or maybe tackle it functionally.
   *
   * Smoothest workflow would be, if you could call Simulation.setCount(200). Simulation.restart(). Simulation.setIsolated().
   * Simulation.initialize()
   * So it is best to create a class and expose these methods.
   *
   */

  constructor(props) {
    super(props)

    this.container = React.createRef()

    this.state = {
      canvas: {
        id: 'canvas',
        width: 800,
        height: 400
      },
      progressCanvas: {
        id: 'progress-canvas',
        width: 450,
        height: 100
      },
      controls: [
        {
          id: 'isolated',
          label: 'Delež izoliranih',
          options: [
            { value: 0, label: '0%' },
            { value: 25, label: '25%' },
            { value: 50, label: '50%' },
            { value: 75, label: '75%' }
          ],
          value: 0
        },
        {
          id: 'speed',
          label: 'Hitrost',
          options: [
            { value: 1, label: '1x' },
            { value: 2, label: '2x' },
            { value: 3, label: '3x' }
          ],
          value: 1
        }
      ],
      faviconImages: [],
      particleCount: 300,
      particleSpeedMultiplier: 2.5,
      results: [],
      playing: false,
      started: false,
      modalOpened: false
    }
  }

  componentDidMount() {
    const simulationDimensions = this.getSimulationDimensions()
    const progressChartDimensions = this.getProgressChartDimensions()
    const particleCount = this.getParticleCount()
    const particleSpeedMultiplier = this.getParticleSpeedMultiplier(particleCount)

    this.preloadFaviconImages()

    // Set initial values
    this.setState(
      {
        canvas: {
          ...this.state.canvas,
          height: simulationDimensions.height,
          width: simulationDimensions.width
        },
        progressCanvas: {
          ...this.state.progressCanvas,
          height: progressChartDimensions.height,
          width: progressChartDimensions.width
        },
        particleCount,
        particleSpeedMultiplier
      },
      () => {
        // Check received parameters
        // If they are valid you should initialize with received count and isolated
        // This is used for embeding
        let isolatedValid = false
        if (this.props.isolated) {
          if (this.isValueInOptions('isolated', this.props.isolated)) isolatedValid = true
        }
        if (isolatedValid) {
          this.onOptionChange('isolated', this.props.isolated, () => {
            this.initSimulation()
          })
        } else {
          this.initSimulation()
        }
      }
    )
  }

  isLowEndDevice() {
    if (this.props.deviceMemory && this.props.numberOfLogicalProcessors) {
      return this.props.numberOfLogicalProcessors < 4 || this.props.deviceMemory < 2
    }
    return false
  }

  isTouchDevice() {
    return 'ontouchstart' in window || navigator.msMaxTouchPoints
  }

  getParticleCount() {
    // Get correct particle count regarding device capabilites
    // Mobile, performance etc.
    // And speed multiplier

    //if (this.props.count) return this.props.count

    if (window.innerWidth < 600 || this.isLowEndDevice() || this.isTouchDevice()) {
      return this.state.particleCount - 100
    }

    //Default
    return this.state.particleCount
  }

  // Returns particle speed multiplier regarding window width
  getParticleSpeedMultiplier(particleCount) {
    if (window.innerWidth < 600) {
      return 2
    } else if (window.innerWidth > 600 && particleCount < 300) {
      return 3
    }

    // Default
    return this.state.particleSpeedMultiplier
  }

  getProgressChartDimensions() {
    let progressCanvasArea = 450 * 80

    if (window.innerWidth < 600) progressCanvasArea = 350 * 50
    if (window.innerWidth < 450) progressCanvasArea = 200 * 50

    const progressCanvasEl = document.getElementById(this.state.progressCanvas.id)
    let progressCanvasWidth = progressCanvasEl.offsetWidth
    if (progressCanvasWidth > 450) progressCanvasWidth = 450

    return {
      width: progressCanvasWidth,
      height: progressCanvasArea / progressCanvasWidth
    }
  }

  getSimulationDimensions() {
    let canvasArea = 800 * 400
    const containerWidth = this.container.current.offsetWidth
    if (window.innerWidth < 600) canvasArea = 600 * 300

    return {
      width: containerWidth,
      height: Math.round(canvasArea / containerWidth)
    }
  }

  initSimulation() {
    /**
     * Initialize new simulation
     */
    const { isolated, speed } = this.getControlValues()
    this.simulation = new Simulation({
      canvas: {
        id: this.state.canvas.id,
        width: this.state.canvas.width,
        height: this.state.canvas.height
      },
      count: this.state.particleCount,
      isolated,
      speed,
      speedMultiplier: this.state.particleSpeedMultiplier,
      progressCanvas: this.state.progressCanvas
    })

    this.simulation.onEnd = this.onSimulationEnd.bind(this)
    this.simulation.onStart = this.onSimulationStart.bind(this)
  }

  isValueInOptions(controlId, value) {
    const options = this.state.controls.find(c => c.id === controlId).options
    const option = options.find(o => o.value === value)
    if (option) return true
    else return false
  }

  getControlValues() {
    /**
     * return {count: 12, isolated: 13}
     */
    let obj = {}
    this.state.controls.forEach(c => {
      obj[c.id] = c.value
    })
    return obj
  }

  onOptionChange(controlId, value, callback = () => {}) {
    if (this.state.controls.find(c => c.id === controlId).value === value) return
    const updatedControls = this.state.controls.map(c => {
      return c.id === controlId ? { ...c, value } : { ...c }
    })
    this.setState({ controls: updatedControls }, callback)

    if (!this.simulation) return

    switch (controlId) {
      case 'isolated':
        return this.simulation.updateIsolatedPercentage(value)
      case 'count':
        return this.simulation.updateCount(value)
      case 'speed':
        return this.simulation.updateSpeed(value)
      default:
        console.error('Control action not found for control id ', controlId)
    }
  }

  play() {
    this.setState({ playing: true, started: true })
    this.simulation.play()
  }

  onSimulationEnd() {
    this.setState({ playing: false })
    if (this.faviconInterval) clearInterval(this.faviconInterval)
    this.addResult()
  }

  addResult() {
    // Generate new result. Result should contain:
    // Progress canvas image and settings used to generate this image
    // Isolated percentage and number of people
    //TODO: Maybe add also mortality and timeToRecover

    const canvasImage = document.getElementById(this.state.progressCanvas.id).toDataURL()
    const { count, isolated, speed } = this.getControlValues()
    const newResult = {
      image: canvasImage,
      isolated,
      count,
      speed
    }
    this.setState({ results: [...this.state.results, newResult] })
  }

  onSimulationStart() {
    this.setState({ playing: true, started: true })
    this.animateFavicon()
  }

  onModalClose() {
    this.setState({ modalOpened: false })
  }

  preloadFaviconImages() {
    const count = 12

    let images = []
    for (let i = 0; i <= count; i++) {
      images.push(require(`../img/favicons/${i}.png`))
    }
    this.setState({ faviconImages: images })
  }

  animateFavicon() {
    const frames = this.state.faviconImages.length
    let currentFrame = 0
    const metaTag = document.getElementById('dynamicFavicon')

    this.faviconInterval = setInterval(() => {
      if (currentFrame === frames - 1) currentFrame = 0
      const image = this.state.faviconImages[currentFrame]
      metaTag.href = image
      currentFrame++
    }, 100)
  }

  render() {
    return (
      <div className={`simulator ${this.props.embedded ? 'embedded' : ''}`}>
        {!this.props.embedded && (
          <EmbedModal controls={this.state.controls} opened={this.state.modalOpened} onClose={this.onModalClose.bind(this)}></EmbedModal>
        )}

        <div className="inner">
          <SimulationSummary progressCanvas={this.state.progressCanvas}></SimulationSummary>

          <div className="main-canvas" ref={this.container}>
            {!this.props.embedded && (
              <button className="embedButton" onClick={() => this.setState({ modalOpened: true })}>
                <img src={require('../img/embed.svg')} alt="Vdelaj" /> Vdelaj
              </button>
            )}

            {!this.state.playing && !this.props.embedded && (
              <div className="overlay">
                <div className="overlay-box">
                  {this.state.started && (
                    <div className="simulationEnded">
                      <p>
                        <strong>Simulacija končana</strong>
                        <br></br>Rezultati simulacije so prikazani spodaj.
                      </p>
                    </div>
                  )}
                  <div className="controls">
                    <Controls onChange={this.onOptionChange.bind(this)} controls={this.state.controls} renderOnly={['isolated']}></Controls>
                  </div>

                  <button className="styledButton primary bigger play" onClick={this.play.bind(this)}>
                    Začni simulacijo
                    <img src={require('../img/play_white.svg')} alt="Play" />
                  </button>
                </div>
              </div>
            )}

            {!this.state.playing && this.props.embedded && (
              <div className="overlay">
                <div className="overlay-box embedded">
                  {!this.state.started && (
                    <button className="styledButton embeddedControlButton" onClick={this.play.bind(this)}>
                      Začni simulacijo
                      <img src={require('../img/play.svg')} alt="Play" />
                    </button>
                  )}
                  {this.state.started && (
                    <button className="styledButton embeddedControlButton" onClick={this.play.bind(this)}>
                      Ponovi simulacijo
                      <img src={require('../img/restart.svg')} alt="Play" />
                    </button>
                  )}
                </div>
              </div>
            )}

            <canvas id={this.state.canvas.id} width={this.state.canvas.width} height={this.state.canvas.height}></canvas>
          </div>
        </div>
        {!this.props.embedded && <Results results={this.state.results}></Results>}
      </div>
    )
  }
}

export default withPerfData(Simulator)
