import React, { useState } from 'react'
import MoreContent from './MoreContent'
import ShareButton from './ShareButton'

function TextContent() {
  const [readMore, toggleReadMore] = useState(false)
  return (
    <div className="textContent">
      <p>
        Širjenje virusa težko popolnoma zaustavimo, saj se med ljudmi širi z eksponentjo rastjo, lahko pa ga znatno omejimo z ukrepi, kot je na primer
        samoizolacija. Spodnja simulacija prikazuje, kako delež izoliranih ljudi vpliva na hitrost širjenja virusa, število okuženih in število
        prebolelih.<br></br>
        <strong>Simulacija je poenostavljen model širjenja virusov in ne predstavlja dejanskega stanja v družbi.</strong>
      </p>
      <div className="textShare">
        <p>
          S tem prikazom želimo dvigniti ozaveščenost o tem, kako pomembna je samoizolacija pri omejevanju širjenja nevarnih virusov ter se hkrati
          pridružujemo akciji <strong>#ostanidoma</strong>.
        </p>
        <div className="share">
          <ShareButton></ShareButton>
        </div>
      </div>

      {readMore ? (
        <MoreContent readLess={() => toggleReadMore(false)}></MoreContent>
      ) : (
        <button className="styledButton primary" onClick={() => toggleReadMore(true)}>
          Kako deluje?
        </button>
      )}
    </div>
  )
}

export default TextContent
