import React, { Component } from 'react'
import PropTypes from 'prop-types'
import colors from './colors'
export default class Summary extends Component {
  static propTypes = {
    progressCanvas: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <div className="summary">
        <div className="counters">
          <h5>Stanje ljudi</h5>
          <table className="numbers">
            <tbody>
              <tr>
                <td>
                  <span className="dot" style={{ background: colors['healthy'] }}></span>
                </td>
                <td>Neokuženi</td>
                <td style={{ color: colors['healthy'] }}>
                  <span id="healthy-count"></span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="dot" style={{ background: colors['infected'] }}></span>
                </td>
                <td>Okuženi</td>
                <td style={{ color: colors['infected'] }}>
                  <span id="infected-count"></span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="dot" style={{ background: colors['recovered'] }}></span>
                </td>
                <td>Preboleli</td>
                <td style={{ color: colors['recovered'] }}>
                  <span id="recovered-count"></span>
                </td>
              </tr>
              {/* <tr>
                <td>
                  <span
                    className="dot"
                    style={{ background: colors["dead"] }}
                  ></span>
                </td>
                <td>Umrli</td>
                <td style={{ color: colors["dead"] }}>
                  <span id="dead-count"></span>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>

        <div className="progress-canvas">
          <h5>Stanje ljudi skozi čas</h5>
          <canvas id={this.props.progressCanvas.id} width={this.props.progressCanvas.width} height={this.props.progressCanvas.height}></canvas>
        </div>
      </div>
    )
  }
}
