import React from 'react'

function MoreContent(props) {
  return (
    <div className="moreContent">
      <button className="styledButton primary" onClick={props.readLess}>
        Skrij dodatno vsebino
      </button>

      <h3>Kako deluje?</h3>
      <p>
        Naključno postavimo <i>N</i>&nbsp;
        <span className="healthy">
          <span className="dot healthy"></span>neokuženih ljudi &nbsp;
        </span>
        in dodamo eno
        <span className="infected">
          <span className="dot infected"></span>
          okuženo &nbsp;
        </span>
        osebo. Osebe premikamo v naključnih smereh in predpostavimo, da se ob vsakem stiku okužene osebe z neokuženo virus uspešno prenese. Po
        določenem časovnem intervalu okužena oseba virus&nbsp;
        <span className="infected recovered">
          <span className="dot recovered"></span>
          preboli, &nbsp;
        </span>
        kar pomeni, da ne more več okužiti niti prenašati virusa na druge.
      </p>
      <p>
        Določimo še delež
        <span className="infected isolated">
          <span className="dot isolated"></span>
          izoliranega prebivalstva, &nbsp;
        </span>
        kar v simulaciji pomeni, kolikšna je verjetnost, da se posamezna enota ne bo premikala. Za izolirano osebo še vedno obstaja neka verjetnost,
        da se okuži, saj jo lahko okuži druga neizolirana oseba, ki pride v stik z njo. Ugotovimo, da je verjetnost za okužbo izolirane osebe obratno
        sorazmerna z deležem izoliranega prebivalstva. Večji del prebivalstva je izoliranega, manjša je verjetnost, da se bo izolirana oseba okužila.
        Za okužene izolirane osebe velja, da virusa ne morejo več širiti.
      </p>
      <p>
        Hitro ugotovimo, da pri 0 % stopnji izolacije število okuženih skokovito narašča medtem ko se pri stopnji 50 % število okuženih veča znatno
        počasneje.
      </p>

      <div className="imagePreview">
        <figure>
          <img src={require('../img/stopnja_0.png')} alt="Stanje ljudi skozi čas pri 0% stopnji izolacije" />
          <figcaption>Stanje obolenj skozi čas pri 0% stopnji izolacije</figcaption>
        </figure>

        <figure>
          <img src={require('../img/stopnja_50.png')} alt="Stanje ljudi skozi čas pri 50% stopnji izolacije" />
          <figcaption>Stanje obolenj skozi čas pri 50% stopnji izolacije</figcaption>
        </figure>
      </div>

      <h3>Interpretacija</h3>

      <p>
        Četudi se pri 50% stopnji samoizolacije okužijo vsi - <strong>kar v realnem svetu ni mogoče</strong> - smo z bolj sploščeno krivuljo dosegli,
        da se zdravstvene kapacitete države ne preobremenijo in s tem močno zmanjšali verjetnost, da pomoči potrebni ne dobijo zdravstvene pomoči.
        <br></br>Povprečno število postelj za akutna stanja v Evropskih bolnišnicah je 433 na 100.000 prebivalcev (
        <a href="https://gateway.euro.who.int/en/hfa-explorer/#A4GB97jLWo" target="_blank" rel="noopener noreferrer">
          Vir
        </a>
        ), država pa v kriznih razmerah lahko spremeni namembnost zdravstvenih kapacitet, zato predpostavimo, da zdravstveno pomoč lahko prejme 5%
        državljanov na enkrat. Če to črto vrišemo v dobljema rezultata to izgleda takole:
      </p>

      <div className="imagePreview">
        <figure>
          <img
            src={require('../img/stopnja_0_line.png')}
            srcSet={`${require('../img/stopnja_0_line@2x.png')} 2x`}
            alt="Stanje ljudi skozi čas pri 0% stopnji izolacije"
          />
          <figcaption>0% izoliranih, 5% zdravstvenih kapacitet</figcaption>
        </figure>

        <figure>
          <img
            src={require('../img/stopnja_50_line.png')}
            srcSet={`${require('../img/stopnja_50_line@2x.png')} 2x`}
            alt="Stanje ljudi skozi čas pri 50% stopnji izolacije"
          />
          <figcaption>50% izoliranih, 5% zdravstvenih kapacitet</figcaption>
        </figure>
      </div>

      <p>
        Vidimo, da pri 50% stopnji izolacije rdeča krivulja ne presega tako strogo zdravstvenih kapacitet, kar pomeni večjo verjetnost, da bodo
        okuženi deležni zdravstvene pomoči. S tem smo izredno zmanjšali smrtnost virusa in razbremenili zdravstveni sistem države poleg tega pa
        preprečili tudi veliko število posrednih žrtev, ki bi, zaradi bolezenskih stanj nepovezanih s COVID-19, potrebovali pomoč v bolnišnici.
      </p>

      <p>
        <hr />
      </p>
    </div>
  )
}

export default MoreContent
