export default class Counters {
  constructor(particles) {
    this.states = [
      {
        label: 'healthy',
        element: document.getElementById('healthy-count'),
        count: 0
      },
      {
        label: 'infected',
        element: document.getElementById('infected-count'),
        count: 0
      },
      {
        label: 'recovered',
        element: document.getElementById('recovered-count'),
        count: 0
      }
      // {
      //   label: "dead",
      //   element: document.getElementById("dead-count"),
      //   count: 0
      // }
    ]

    this.update(particles)
  }

  update(particles) {
    let returnObj = {}
    for (let i = 0; i < this.states.length; i++) {
      const state = this.states[i]
      const newStateCount = this.getCountByState(particles, state.label)
      if (newStateCount !== state.count) {
        state.count = newStateCount

        this.updateDOM()
      }
      returnObj[state.label] = newStateCount
    }
    return returnObj
  }

  updateDOM() {
    for (let i = 0; i < this.states.length; i++) {
      const state = this.states[i]
      state.element.innerHTML = state.count
    }
  }

  getCountByState(particles, state) {
    return particles.filter(p => p.state === state).length
  }
}
